/*=================================================*/

.logo2 img {
  padding-left: 5%;
  height: 65px;
  margin-left: 100%;
}

nav {
  margin-bottom: 0px;
}

.nav-links2 .font {
  font-weight: 700;
}

nav .nav-links2 {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

nav .nav-links2 a {
  text-transform: uppercase;
  word-spacing: 3px;
  font-size: 15px;
  font-weight: bold;
}

nav .bar {
  font-size: 30px;
  display: none;
  cursor: pointer;
}


/* ===================MEDIA=QUERIES================
  
  /* Big tablet to 1200px (widths smaller taht the 1140px row) (max-width: 1200px)*/

@media only screen and (max-width: 1250px) {
  nav .nav-links {
    width: 68%;
  }
}

/* Small tablet to big tablet: from 768px to 1023px  (max-width: 1024px)*/

@media only screen and (max-width: 1024px) {
  nav .nav-links {
    width: 78%;
  }
}

@media only screen and (max-width: 480px) {
  nav .nav-links {
    padding: 2px;
  }
}


/* ===================MEDIA=QUERIES================
  
  /* Small phones to small tablets: from 481px to 767px (max-width: 767px) */

@media all and (max-width: 768px) {
  nav .bar {
    margin-right: 20px;
    display: block;
  }
  nav .nav-links2 a {
    font-size: 18px;
    border-bottom: 1px solid #2e56a0;
  }

  nav .nav-links2 {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: white;
    width: 65%;
    z-index: 1;
    transform: translateX(-500px);
    transition: 0.5s ease-in-out;
    margin-top: 0;
    padding-top: 10px;
  }
  nav .nav-links2 li {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  nav .nav-links2 li:last-of-type {
    border-bottom: 0px solid #ccc;
  }
}
