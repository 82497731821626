/*================Contact-Us================*/
.hero-section {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("./Images/contactus3.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}

/*================API-DEV================*/
.hero-section-api {
  background-image: linear-gradient(rgba(240, 240, 240, 0.6), rgba(0, 0, 0, 0.6)),
    url("./Images/api.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}

/*================Cloud================*/
.hero-section-cloud {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./Images/cloud3.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}
/*================Digital================*/
.hero-section-digi {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./Images/digi-marketing.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}
/*================Hardware/software================*/
.hero-section-hard {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./Images/Hero3.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}

/*================Mobile DEv================*/
.hero-section-mob {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./Images/Mobile1.png");
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}
/*================WebDev================*/
.hero-section-web {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./Images/webdev.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}





