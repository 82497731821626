/*=================================================*/

.logo2 img {
  padding-left: 5%;
  height: 65px;
  margin-left: 100%;
}

nav {
  margin-bottom: 0px;
}

.nav-links2 .font {
  font-weight: 700;
}

nav .nav-links2 {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

nav .nav-links2 a {
  text-transform: uppercase;
  word-spacing: 3px;
  font-size: 15px;
  font-weight: bold;
}

nav .bar {
  font-size: 30px;
  display: none;
  cursor: pointer;
}


/* ===================MEDIA=QUERIES================
  
  /* Big tablet to 1200px (widths smaller taht the 1140px row) (max-width: 1200px)*/

@media only screen and (max-width: 1250px) {
  nav .nav-links {
    width: 68%;
  }
}

/* Small tablet to big tablet: from 768px to 1023px  (max-width: 1024px)*/

@media only screen and (max-width: 1024px) {
  nav .nav-links {
    width: 78%;
  }
}

@media only screen and (max-width: 480px) {
  nav .nav-links {
    padding: 2px;
  }
}


/* ===================MEDIA=QUERIES================
  
  /* Small phones to small tablets: from 481px to 767px (max-width: 767px) */

@media all and (max-width: 768px) {
  nav .bar {
    margin-right: 20px;
    display: block;
  }
  nav .nav-links2 a {
    font-size: 18px;
    border-bottom: 1px solid #2e56a0;
  }

  nav .nav-links2 {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: white;
    width: 65%;
    z-index: 1;
    -webkit-transform: translateX(-500px);
            transform: translateX(-500px);
    transition: 0.5s ease-in-out;
    margin-top: 0;
    padding-top: 10px;
  }
  nav .nav-links2 li {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  nav .nav-links2 li:last-of-type {
    border-bottom: 0px solid #ccc;
  }
}

/*================Contact-Us================*/
.hero-section {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(/static/media/contactus3.3beef776.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}

/*================API-DEV================*/
.hero-section-api {
  background-image: linear-gradient(rgba(240, 240, 240, 0.6), rgba(0, 0, 0, 0.6)),
    url(/static/media/api.239b8c67.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}

/*================Cloud================*/
.hero-section-cloud {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/static/media/cloud3.2d270cda.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}
/*================Digital================*/
.hero-section-digi {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/static/media/digi-marketing.db8338ef.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}
/*================Hardware/software================*/
.hero-section-hard {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/static/media/Hero3.db41f6c9.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}

/*================Mobile DEv================*/
.hero-section-mob {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/static/media/Mobile1.75a48e2c.png);
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}
/*================WebDev================*/
.hero-section-web {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/static/media/webdev.0dd13e11.jpg);
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  width: 100%;
}






/*==================HEADINGS====================*/
h1 {
  text-transform: uppercase;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  word-spacing: 2px;
  letter-spacing: 1px;
}

/***************************************/
/*=============Sections===============*/

.section {
  background-color: hsla(200, 67%, 39%, 1);
  background-size: cover;
  background-attachment: fixed;
  height: 70vh;
  width: 100%;
}

.sec-one-div-one {
  float: left;
  margin: 150px 0 0 100px;
}

.sec-one-div-one p {
  color: #fff;
  font-size: 36px;
  word-spacing: 1px;
  font-weight: 600;
}
/*=======================================*/

.Find-Mentor-Grid .ui.card {
  width: 340px;
}
.Find-Mentor-Grid .ui.card > .image > img {
  height: 290px;
  width: 340px;
}
.ui.grid > .column:not(.row) {
  margin-left: 12px;
  display: inline-table;
}

/*=================Media-Queries========================*/
@media only screen and (max-width: 480px) {
  .section {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url(/static/media/Hero.3ed020aa.jpg);
    background-size: cover;
    background-attachment: fixed;
    height: 70vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-position: center;
  }

  .sec-one-div-one {
    margin: 60px 0 0 15px;
  }
}

