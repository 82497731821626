/*==================HEADINGS====================*/
h1 {
  text-transform: uppercase;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  word-spacing: 2px;
  letter-spacing: 1px;
}

/***************************************/
/*=============Sections===============*/

.section {
  background-color: hsla(200, 67%, 39%, 1);
  background-size: cover;
  background-attachment: fixed;
  height: 70vh;
  width: 100%;
}

.sec-one-div-one {
  float: left;
  margin: 150px 0 0 100px;
}

.sec-one-div-one p {
  color: #fff;
  font-size: 36px;
  word-spacing: 1px;
  font-weight: 600;
}
/*=======================================*/

.Find-Mentor-Grid .ui.card {
  width: 340px;
}
.Find-Mentor-Grid .ui.card > .image > img {
  height: 290px;
  width: 340px;
}
.ui.grid > .column:not(.row) {
  margin-left: 12px;
  display: inline-table;
}

/*=================Media-Queries========================*/
@media only screen and (max-width: 480px) {
  .section {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url("../Images/Hero.jpg");
    background-size: cover;
    background-attachment: fixed;
    height: 70vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-position: center;
  }

  .sec-one-div-one {
    margin: 60px 0 0 15px;
  }
}
